
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../../Axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

function Home() {

  const [buss1, setBuss1] = useState(undefined)
  const [buss2, setBuss2] = useState(undefined)
  const [buss3, setBuss3] = useState(undefined)

  useEffect(() => {
    getBusiness1(14)
    getBusiness2(58)
    getBusiness3(56)
  }, [])

  const getBusiness1 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss1(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }

  const getBusiness2 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss2(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }

  const getBusiness3 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss3(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=AW-16490602917`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-16490602917');
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const gtag_report_conversion = (url) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };

    window.gtag('event', 'conversion', {
      'send_to': 'AW-16490602917/6L9VCK3DxsYZEKXDqrc9',
      'transaction_id': '',
      'event_callback': callback,
    });
    return false;
  };


  return (
    <div style={{ height: "auto", overflow: "hidden" }}>
      <Helmet>
        <title>Randevu Defterim | Randevu Programı</title>
        <meta name='description' content='Türkiyenin Ücretsiz Randevu takip programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
        <meta name='description' content='Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
        <meta property="og:title" content="Randevu Takip Programı"></meta>
        <meta property="og:description" content="Türkiyenin Ücretsiz Randevu takip sistemi Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
        <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta property="og:url" content="https://www.randevudefterim.com/randevu-takip-programi"></meta>
        <meta name="twitter:title" content="Randevu Takip Programı"></meta>
        <meta name="twitter:description" content="Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
        <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta name="keywords" content="randevu takip programı, randevu yönetimi, online randevu, randevu uygulaması, randevu takibi, randevu planlama, randevu yazılımı, müşteri randevusu, işletme randevusu, randevu defterim" />
      </Helmet>
      <MainMenu type={2} />



      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "40px" }}>Neler Yapabiliyorsun?</h2>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#50aff2" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Sınırsız Randevu Oluştur</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Müşterilerine sınırsız randevu oluşturabilirsin.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "green" }} class="ri-whatsapp-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Whatsapp Mesajı Yolla</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Müşterilerine sunduğun hizmetleri sergile.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#16CDB7" }} class="ri-gift-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Şubelerini Tek Yerden Yönet</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Sınırsız işletme açarak şubelerini tek yerden yönetebilirsin.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#c24df0" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Otomatik mesajlar</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Randevularını verdiğinde otomatik mesajlar müşterilerine gitsin.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        buss1 != undefined && buss2 != undefined && buss3 != undefined &&

        <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
              <div className='container-fluid' style={{ marginBottom: "30px", marginTop: "50px" }}>
                <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "10px" }}>Aramıza katılan son üyelerimiz!</h2>
                <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "20px" }}>Kayıt olarak sen de doğru tercihi yapabilirsin!</p>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss1.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss1.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss1.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss1.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss1.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss2.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss2.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss2.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss2.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss2.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss3.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss3.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss3.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss3.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss3.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid' style={{ marginBottom: "30px", marginTop: "50px" }}>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "10px" }}>Üye ol sınırsız kullan!</h2>
              <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "20px" }}>Kurumsal firmalar Whatsapp kullanıyor. Sen de üye ol müşterilerine whatsapp ile mesaj yolla!</p>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                    <div style={{ fontSize: "16px", color: "white" }}>-</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>Demo Paket</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sınırsız E-Posta</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sınırsız Whatsapp Mesajı</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Otomatik Mesajlar</div>

                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold",color:"orange" }}>ÜCRETSİZ!!</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://wa.me/905424981083"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Demo Talep Et</a></div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                    <div style={{ fontSize: "16px", color: "white" }}>-</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>Aylık Paket</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız E-Posta</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Whatsapp Mesajı</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Otomatik Mesajlar</div>

                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>1990 TL</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a target="_blank" href={"https://wa.me/905424981083"} style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10, outline: "none", border: "none", fontWeight: "bold" }}> Satın Al</a></div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>11.688 TL Kârdasın</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>Yıllık Paket</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız E-Posta</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Whatsapp Mesajı</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Otomatik Mesajlar</div>

                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px" }}>23.988 TL</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", color:"orange" }}>12.000 TL</div>

                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a target="_blank" href={"https://wa.me/905424981083"} style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10, outline: "none", border: "none", fontWeight: "bold" }}> Satın Al</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container-fluid' style={{ marginTop: "100px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6'>

                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <h3 style={{ fontSize: "34px" }}>Nasıl Çalışır?</h3>
                      <p style={{ fontSize: "18px", color: "#4f4f4f" }}>
                        Uygulamanın nasıl çalıştığını yandaki videoyu izleyerek öğrenebilirsin.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6' style={{ marginTop: "100px" }}>
                  <div style={{ height: "600px", textAlign: "center" }}>
                    <iframe
                      style={{ borderRadius: "30px", border: "1px solid #aaa" }}
                      src="https://drive.google.com/file/d/1xaQIVLNigSx4RRUD9Yv3sg3dcZuyU1I8/preview"
                      width="100%"
                      height="480"
                      allow="autoplay"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ marginTop: "20px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>

                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <h3 style={{ fontSize: "34px" }}>Uygulamamız çok yakında hem Google Play store hem de App store'da!</h3>
                      <p style={{ fontSize: "18px", color: "#4f4f4f" }}>
                        Web arayüzünde verdiğimiz deneyimi uygulamamızda sana sunuyoruz. Uygulamayı indirip randevularını vermeye devam edebilir, whatsapp mesajları gönderebilir ve e-posta atabilirsin.
                      </p>
                      <div style={{ textAlign: "start", marginTop: "100px" }}>

                        <img src='google.png' style={{ width: "200px" }} />


                        <img src='apple.png' style={{ width: "200px" }} />

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "100px" }}>
                  <div style={{ height: "600px", textAlign: "center" }}>
                    <img src='phone.png' style={{ height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "40px" }}>Neden Randevu Defterim?</h2>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#50aff2", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-money-dollar-circle-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Ücretsiz</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Yığınla para ödemekten sıkıldığını biliyoruz. O yüzden randevularını artık ücretsiz takip edebilirsin.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#ed894e", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-customer-service-2-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Sınırsız işletme</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Sınırsız sayıda işletme açabilir ve tek yerden bütün randevularını yönetebilirsin.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#16CDB7", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-user-2-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Sınırsız Çalışan</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>İşletmene birden çok çalışan ekleyerek hepsinin randevularını tek yerden yönetebilirsin.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#c24df0", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Sınırsız E-Posta</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Müşterilerine özel günlerde istediğin kadar e-posta yollayabilirsin.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px", backgroundColor: "white" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div style={{ height: "700px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center" }}>
                <h3 style={{ fontSize: "34px", color: "#05036B", textAlign: "center" }}>Ücretsiz Randevularını Takip Et</h3>
                <p style={{ fontSize: "18px", color: "black", textAlign: "center" }}>İşletmeni açtıktan sonra kendini de çalışan olarak ekleyebilir sonrasında randevu vermeye başlayabilirsin. Randevularından önce müşterilerine e-postalar yollayabilir. Ücretsiz planlarını gerçekleştirmenin tadını çıkarabilirsin.</p>
                <a href={"https://wa.me/905424981083"} target='_blank' style={{ backgroundColor: "#f5914e", color: "white", textDecoration: "none", padding: "10px", borderRadius: "10px" }}>Demo Talep Et</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ backgroundColor: "white", marginTop: "50px", height: "auto", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ paddingTop: "100px" }}>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", flexDirection: "column" }}>
                    <a style={{ textDecoration: "none", color: "black", fontSize: "44px", fontWeight: "bold", display: "block", width: "100%" }} href={"https://wa.me/905424981083"} target='_blank'>Demo Talep Et!</a>
                    <div style={{ textAlign: "start" }}>

                      <img src='google.png' style={{ width: "200px" }} />


                      <img src='apple.png' style={{ width: "200px" }} />

                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", textAlign: "start" }}>
                    <div style={{}}>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/blog">Blog</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-takip-programi">Randevu Takip Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/dis-hekimi-randevu-programi">Diş Hekimi Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-salonu-randevu-programi">Güzellik Salonu Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/diyetisyen-randevu-programi">Diyetisyen Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/berber-randevu-programi">Berber Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-merkezi-randevu-programi">Güzellik Merkezi Randevu Programı</Link>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                    <p style={{ fontSize: "14px", textAlign: "start", width: "100%" }}><i style={{ fontSize: "18px", marginRight: "10px" }} class="ri-customer-service-line"></i>+90 (0549) 550 05 09</p>
                    <a style={{ textDecoration: "none", color: "black", fontSize: "18px", display: "block", textAlign: "start", width: "100%" }} href="https://www.instagram.com/randevu_defterim/">
                      Bizi Takip Edin! <i class="ri-instagram-line"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
