import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Appointments() {
    const authenticated = useSelector(state => state.login)
    const [appointments, setAppointments] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getAppointments = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + "/appointments");
            setIsLoading(false)
            setAppointments(response.data.appointments)

        } catch (error) {
            setIsLoading(false)
            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    useEffect(() => {
        getAppointments()
    }, [])

    const calculateAppointments = () => {

        var total = 0;
        if (appointments.length > 0) {
            var i;
            for (i = 0; i < appointments.length; i++) {

                if (new Date(appointments[i].date).getDate() > new Date().getDate() && new Date(appointments[i].date).getMonth() === new Date().getMonth() && new Date(appointments[i].date).getFullYear() === new Date().getFullYear()) {
                    total++
                }

            }

            return total;

        }
        else {
            return 0;
        }
    }

    console.log(appointments)

    return (
        <div className="App">
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            {
                                isLoading &&
                                <div>
                                    <div>
                                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Bugün</h2>
                                        <Skeleton count={5} />
                                    </div>
                                    <div>
                                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Bu Ay</h2>
                                        <Skeleton count={5} />
                                    </div>
                                </div>

                            }
                            {
                                calculateAppointments() == 0 && !isLoading &&
                                <p>Herhangi bir randevu bulamadık!</p>
                            }
                            {
                                appointments.length > 0 &&
                                <div>
                                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Bugün</h2>
                                    {
                                        appointments.map((appointment, index) => {
                                            if (new Date(appointment.date).getDate() === new Date().getDate() && new Date(appointment.date).getMonth() === new Date().getMonth() && new Date(appointment.date).getFullYear() === new Date().getFullYear())
                                                return (
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "10px" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px" }}>
                                                                <span style={{ fontSize: "14px" }}>{appointment.businessname}</span>
                                                            </div>
                                                            <p style={{ textAlign: "start", margin: "0px" }}><b>{appointment.name}</b> ile <b>{appointment.starttime}-{appointment.endtime}</b> saatleri arasında <b>{appointment.servicename}</b> hakkında bir randevun var.</p>
                                                            <p style={{ textAlign: "start", marginTop: "10px", fontSize: "13px" }}><i class="ri-phone-line"></i> Ara: <a href={"tel:" + appointment.phone} style={{ textDecoration: "none", color: "black" }}>{appointment.phone}</a></p>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                    }
                                </div>
                            }
                            {
                                appointments.length > 0 &&
                                <div>
                                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Bu Ay</h2>
                                    {
                                        appointments.map((appointment, index) => {
                                            if (new Date(appointment.date).getDate() > new Date().getDate() && new Date(appointment.date).getMonth() === new Date().getMonth() && new Date(appointment.date).getFullYear() === new Date().getFullYear())
                                                return (
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "10px" }}>
                                                            <p style={{ textAlign: "end", fontSize: "12px" }}>{appointment.date.split("T")[0]}</p>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px" }}>
                                                                <span style={{ fontSize: "14px" }}>{appointment.businessname}</span>
                                                            </div>
                                                            <p style={{ textAlign: "start", margin: "0px" }}><b>{appointment.name}</b> ile <b>{appointment.starttime}-{appointment.endtime}</b> saatleri arasında bir randevun var.</p>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointments;
