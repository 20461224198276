
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, Chip, Switch, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Line, Circle } from 'rc-progress';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Businesses() {

    let { id } = useParams();

    const [selectedBusiness, setSelectedBusiness] = useState({
        "id": -1,
        "ownerid": -1,
        "name": "",
        "phone": "",
        "mail": "",
        "address": "",
        "city": "",
        "area": "",
        "logo": null,
        "isactive": 0,
        "point": 0
    })

    const [isSendMailModal, setIsSendMailModal] = useState(false)
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({
        id: -1,
        businessid: -1,
        message: "",
        title: ""
    })
    const [isUpdateCustomerModal, setIsUpdateCustomerModal] = useState(false)
    const [isSendMailLoading, setIsSendMailLoading] = useState(false)

    const [isSendWpModal, setIsSendWpModal] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const getBusiness = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get('/businesses/' + id);

            setSelectedBusiness(response.data)
            setIsLoading(false)

        } catch (error) {

            if (error.response != undefined) {
                setIsLoading(false)
                toast.error(error.response.data.error)
            }

            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }


    const getMessages = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/messages");

            setMessages(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const sendMail = async () => {


        if (selectedMessage.id != -1) {
            setIsSendMailLoading(true)




            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/operations",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: addRandevu.mail
                    }
                );


                setIsSendMailModal(false)

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                toast.success("E-Posta yollanmıştır.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)


        }
        else {
            toast.error("Lütfen Mesaj Şablonu seçiniz.")
        }
    }

    const sendWp = async () => {


        if (selectedMessage.id != -1) {
            setIsSendMailLoading(true)




            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/whatsapp",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: addRandevu.phone
                    }
                );


                setIsSendWpModal(false)

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                toast.success("Whatsapp mesajı yollanmıştır.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)


        }
        else {
            toast.error("Lütfen Mesaj Şablonu seçiniz.")
        }
    }



    const [personelList, setPersonelList] = useState([])
    const [isAddPersonelModal, setIsAddPersonelModal] = useState(false)
    const [isAddLoading, setIsAddLoading] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [personel, setPersonel] = useState({
        personelphone: "",
        duration: "",
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    })
    const [pageIndex, setPageIndex] = useState(-1)
    const [selectedPersonel, setSelectedPersonel] = useState(-1)
    const [selectedPersonelId, setSelectedPersonelId] = useState(-1)
    const [isAddRandevuModal, setIsAddRandevuModal] = useState(false)
    const [servicesList, setServicesList] = useState([])
    const [isAppointmentAddLoading, setIsAppointmentAddLoading] = useState(false)
    const [isShowRandevuModal, setIsShowRandevuModal] = useState(false)
    const [randevu, setRandevu] = useState(undefined)
    const [addRandevu, setAddRandevu] = useState({
        starttime: "",
        endtime: "",
        date: "",
        servisid: -1,
        name: "",
        phone: "",
        mail: "",
        birthdate: null,
        userid: null,
        state: 2
    })
    const [years, setYears] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null)

    useEffect(() => {
        // Generate the list of years from 2024 to 2100
        const startYear = 2024;
        const endYear = 2100;
        const yearArray = [];
        for (let year = startYear; year <= endYear; year++) {
            yearArray.push(year);
        }
        setYears(yearArray);
    }, []);

    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())

    const [days, setDays] = useState([]);

    useEffect(() => {
        if (selectedPersonel != -1)
            setPageIndex(0)
    }, [selectedPersonel])

    useEffect(() => {
        const getDaysInMonth = (year, month) => {
            return new Date(year, month + 1, 0).getDate();
        };

        const daysInMonth = getDaysInMonth(year, month);
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        setDays(daysArray);
    }, [month, year]);

    const getDayName = (year, month, day) => {
        const date = new Date(year, month, day);
        const dayNames = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        return dayNames[date.getDay()];
    };

    const getMonthName = (month) => {
        const dayNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        return dayNames[month];
    };

    const getPersonels = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/personels");

            setPersonelList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }
    console.log(id)
    useEffect(() => {

        getBusiness()
    }, [])

    useEffect(() => {
        if (selectedBusiness.id != -1) {
            getPersonels()
        }

    }, [selectedBusiness])

    const getPersonelById = () => {

        let i;
        for (i = 0; i < personelList.length; i++) {
            if (personelList[i].id == selectedPersonel) {
                return personelList[i]
            }
        }
    }

    const isSuitable = (year, month, day) => {

        const selpersonel = () => {
            let i;
            for (i = 0; i < personelList.length; i++) {
                if (personelList[i].id == selectedPersonel) {
                    return personelList[i]
                }
            }
        }

        const mypersonel = selpersonel()

        if (getDayName(year, month, day) == "Pazartesi" && mypersonel.monday == "1")
            return true;
        else if (getDayName(year, month, day) == "Salı" && mypersonel.tuesday == "1")
            return true;
        else if (getDayName(year, month, day) == "Çarşamba" && mypersonel.wednesday == "1")
            return true;
        else if (getDayName(year, month, day) == "Perşembe" && mypersonel.thursday == "1")
            return true;
        else if (getDayName(year, month, day) == "Cuma" && mypersonel.friday == "1")
            return true;
        else if (getDayName(year, month, day) == "Cumartesi" && mypersonel.saturday == "1")
            return true;
        else if (getDayName(year, month, day) == "Pazar" && mypersonel.sunday == "1")
            return true;

        return false;
    }


    const savePersonel = async () => {

        setIsAddLoading(true)
        try {

            const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/personels",
                personel
            );

            getPersonels()

            setIsAddLoading(false)

            toast.success("Personel Başarıyla Eklenmiştir.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error + " Kayıt için kullandığın numara randevudefterim.com'a üye olmalıdır.")
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

        setTimeout(() => {
            setIsAddLoading(false)
        }, 2000)


    }

    const saveAppointment = async () => {

        if (addRandevu.servisid != -1) {

            setIsAppointmentAddLoading(true)
            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments",
                    addRandevu
                );

                getAppointments()

                setIsAppointmentAddLoading(false)
                setIsAddRandevuModal(false)

                toast.success("Randevu Başarıyla Eklenmiştir.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

            setTimeout(() => {
                setIsAppointmentAddLoading(false)
            }, 2000)

        }
        else {
            toast.error("Lütfen hizmet sayfasından hizmet ekleyiniz.")
        }

    }

    function formatTime(date) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    function generateTimeSlots(start, end, duration) {
        const slots = [];
        const startTime = new Date(`1970-01-01T${start}:00`);
        const endTime = new Date(`1970-01-01T${end}:00`);

        while (startTime < endTime) {
            const endSlotTime = new Date(startTime.getTime() + duration * 60000);
            if (endSlotTime > endTime) break;

            slots.push(`${formatTime(startTime)} - ${formatTime(endSlotTime)}`);
            startTime.setTime(startTime.getTime() + duration * 60000);
        }

        return slots;
    }
    const [duration, setDuration] = useState(30);
    const timeSlots = generateTimeSlots('08:00', '18:00', getPersonelById() != undefined ? getPersonelById().duration : 30);
    const [holidays, setHolidays] = useState([])
    const deletePersonel = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/personels/" + id);

            getPersonels()

            toast.success("Personel başarılı bir şekilde silinmiştir.")

        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const getAppointments = async (id) => {

        try {

            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments/free" + "?month=" + (month + 1) + "&year=" + year);

            setAppointments(response.data)


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const getServices = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/services");

            setServicesList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    useEffect(() => {
        getHolidays()
    }, [])

    const getHolidays = async () => {

        try {
            const response = await axiosInstance.get('https://www.googleapis.com/calendar/v3/calendars/tr.turkish%23holiday@group.v.calendar.google.com/events?key=AIzaSyBUoMwVG2CBw6J_L4aodEu0Hfb8BB0toT4');

            setHolidays(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const isDayHoliday = (date) => {

        var i;
        for (i = 0; i < holidays.items.length; i++) {
            if (new Date(holidays.items[i].start.date).setHours(0, 0, 0, 0) == date.setHours(0, 0, 0, 0))
                return holidays.items[i].summary
        }
        return "";
    }

    const calculateCapacity = (day, month, year, selectedper) => {

        var j;

        var appointmentCountList = []
        console.log(days.length)
        for (j = 0; j < days.length; j++) {
            appointmentCountList.push(0)
        }

        let duration = getPersonelById(selectedper).duration

        let seansCount = 9 * 60 / duration

        let mydate = new Date(year, month, day)
        let i;
        for (i = 0; i < appointments.length; i++) {

            var appointmentDate = new Date(appointments[i].date.split("T")[0]);

            // Extract year, month, and day from the dates
            var appointmentYear = appointmentDate.getFullYear();
            var appointmentMonth = appointmentDate.getMonth();
            var appointmentDay = appointmentDate.getDate();

            var myYear = mydate.getFullYear();
            var myMonth = mydate.getMonth();
            var myDay = mydate.getDate();

            // Compare only year, month, and day
            var isSameDate = appointmentYear === myYear && appointmentMonth === myMonth && appointmentDay === myDay;

            if (isSameDate) {

                appointmentCountList[day - 1]++;
            }
        }
        console.log(appointmentCountList, "hello")
        return (appointmentCountList[day - 1] * 100 / seansCount).toFixed(2)
    }


    useEffect(() => {
        getAppointments()
    }, [month, year, selectedPersonelId])


    const deleteAppointment = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments/" + id);

            getAppointments()

            toast.success("Randevu başarılı bir şekilde silinmiştir.")

        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const getRandevu = (slot) => {

        var i;
        for (i = 0; i < appointments.length; i++) {
            if (new Date(appointments[i].date.split("T")[0]).setHours(0, 0, 0, 0) == selectedDate.setHours(0, 0, 0, 0)) {
                if ((appointments[i].starttime + " - " + appointments[i].endtime) == slot) {
                    return appointments[i]
                }
            }
        }

        return undefined;

    }


    if (pageIndex == -1) {
        return (
            <div className="App" style={{ overflow: "hidden", width: "100%" }}>
                <MainMenu type={2} />

                {
                    isLoading &&
                    <div className="container-fluid" style={{ marginTop: "100px" }}>
                        <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                            <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                                <div style={{ backgroundColor: "white", height: "auto", border: "1px solid #EAEAEA", borderRadius: "20px" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                                        <div style={{ width: "200px" }}>
                                            <Skeleton count={1} />
                                        </div>
                                        <div style={{ width: "200px" }}>
                                            <Skeleton count={1} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: isMobile ? "column" : "row" }}>
                                        <div><Skeleton style={{ width: "150px", height: "150px", borderRadius: "150px" }} /></div>
                                        <div style={{ marginLeft: "20px" }}>
                                            <div><Skeleton count={1} /></div>
                                            <div style={{ marginBottom: "10px", width: "200px" }}><Skeleton count={1} /></div>
                                            <div style={{ marginBottom: "10px", width: "200px" }}><Skeleton count={1} /></div>
                                            <div style={{ marginBottom: "10px", width: "200px" }}><Skeleton count={1} /></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <Skeleton count={1} />
                                            </div>
                                            <div style={{ textAlign: "start", marginTop: "20px", marginBottom: "0px" }}>
                                                <Skeleton count={2} />

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "20px", marginTop: "30px" }}>
                                        <div style={{ overflow: "auto", borderBottom: "1px solid #d9d9d9", paddingBottom: "10px", width: "100%" }}>
                                            <Skeleton style={{ width: "100%" }} />
                                        </div>
                                        <div style={{ marginTop: "100px" }}>
                                            <Skeleton count={6} style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !isLoading &&

                    <div className='container-fluid'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", padding: "50px", paddingBottom: "50px" }}>
                                    <div>
                                        <img src={selectedBusiness.logo != null ? selectedBusiness.logo : "/defaultbuss.png"} style={{ width: "150px", height: "150px", borderRadius: "100px", border: "1px solid #d9d9d9" }} /></div>
                                    <div style={{ marginLeft: "20px" }}>
                                        <div><p style={{ textAlign: "start", fontSize: "14px", marginBottom: "10px" }}><b>{selectedBusiness.name}</b></p></div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "black", marginRight: "5px" }} class="ri-map-pin-line"></i><p style={{ textAlign: "start", margin: "0px", color: "black" }}>{selectedBusiness.city} - {selectedBusiness.address}</p></div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "black", marginRight: "5px" }} class="ri-phone-line"></i><p style={{ textAlign: "start", margin: "0px", color: "black" }}>{selectedBusiness.phone}</p></div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "black", marginRight: "5px" }} class="ri-mail-line"></i><p style={{ textAlign: "start", margin: "0px", color: "black" }}>{selectedBusiness.mail}</p></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>

                            <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6'>
                                <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", paddingLeft: "20px", marginTop: "30px" }}>Çalışanlar</h2>

                                <div>
                                    {
                                        personelList.length == 0 &&
                                        <div style={{ marginBottom: "40px" }}>
                                            İşletmende Personel Bulamadık!
                                        </div>
                                    }
                                    {
                                        personelList.length > 0 &&
                                        <div style={{ overflow: "auto", padding: "20px" }}>
                                            <table class="table">
                                                <thead >
                                                    <tr>
                                                        <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Ad Soyad</th>
                                                        <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Süre</th>
                                                        <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        personelList.map((personel, index) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ textAlign: "start", fontSize: "14px" }}>{isMobile ? "" : <img src={personel.image != null ? personel.image : "default.png"} style={{ borderRadius: "40px", height: "40px", width: "40px", marginRight: "10px" }} />}<b>{personel.name}</b></td>
                                                                    <td style={{ textAlign: "start", fontSize: "14px" }}><b style={{ height: "40px", display: "flex", alignItems: "center", fontWeight: "normal" }}>{personel.duration} Dakika</b></td>


                                                                    <td style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                        <Button variant="contained" style={{ marginRight: "10px", border: "none", backgroundColor: "#16CDB7", padding: "10px", borderRadius: "10px", color: "white" }} onClick={() => { setSelectedPersonel(personel.id); setSelectedPersonelId(personel.personelid) }}> Randevu Al</Button>

                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >
        );
    }
    else if (pageIndex == 0) {
        return (
            <div>
                <MainMenu type={2} />

                <div className='container-fluid'>

                    <div className='row justify-content-center'>

                        <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px" }}>

                            <div style={{ overflow: "auto", padding: "20px" }}>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "50px" }}>
                                    <Button onClick={() => { setPageIndex(-1); setSelectedPersonel(-1) }} variant='contained' style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }}><i style={{ fontSize: "20px" }} class="ri-arrow-left-line"></i></Button>
                                    <p style={{ margin: "0px", fontSize: "12px", color: "#969696" }}>Bu ay {appointments.length} adet randevunuz bulunmaktadır.</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "20px" }}>
                                    <FormControl style={{ width: "200px" }}>
                                        <InputLabel id="demo-simple-select-label">Ay</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={month}
                                            label="Ay"
                                            onChange={(e) => setMonth(e.target.value)}
                                        >
                                            <MenuItem value={0}>Ocak</MenuItem>
                                            <MenuItem value={1}>Şubat</MenuItem>
                                            <MenuItem value={2}>Mart</MenuItem>
                                            <MenuItem value={3}>Nisan</MenuItem>
                                            <MenuItem value={4}>Mayıs</MenuItem>
                                            <MenuItem value={5}>Haziran</MenuItem>
                                            <MenuItem value={6}>Temmuz</MenuItem>
                                            <MenuItem value={7}>Ağustos</MenuItem>
                                            <MenuItem value={8}>Eylül</MenuItem>
                                            <MenuItem value={9}>Ekim</MenuItem>
                                            <MenuItem value={10}>Kasım</MenuItem>
                                            <MenuItem value={11}>Aralık</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{ width: "200px", marginLeft: "20px" }}>
                                        <InputLabel id="demo-simple-select-label">Yıl</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={year}
                                            label="Yıl"
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            {
                                                years.map((year) => {
                                                    return (
                                                        <MenuItem value={year}>{year}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <div>
                                        <table class="table" style={{ marginTop: "20px" }}>
                                            <thead >
                                                <tr>
                                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Gün(Sayı)</th>
                                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Doluluk Oranı</th>
                                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Çalışma Durumu</th>
                                                    <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                                    <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {days.map(day => (
                                                    <tr key={day}>
                                                        <td style={{ textAlign: "start", fontSize: "12px" }}>{day} {getMonthName(month)} {getDayName(year, month, day)}</td>
                                                        <td style={{ textAlign: "start", fontSize: "14px" }}> <Line percent={calculateCapacity(day, month, year, selectedPersonel)} strokeWidth={4} strokeColor="#16CDB7" style={{ width: isMobile ? "50px" : "150px", marginRight: "10px" }} />{calculateCapacity(day, month, year, selectedPersonel)}%</td>
                                                        <td style={{ textAlign: "start" }}>{isDayHoliday(new Date(year, month, day)) != "" ? <Chip color="warning" label="Özel Gün"></Chip> : isSuitable(year, month, day) ? <Chip color="success" label="Uygun"></Chip> : <Chip color="error" label="Uygun Değil"></Chip>}</td>
                                                        <td style={{ textAlign: "start" }}></td>
                                                        <td style={{ textAlign: "end" }}>
                                                            <Button
                                                                sx={{
                                                                    color: "white", padding: "10px",
                                                                    border: "none", borderRadius: "10px",
                                                                    backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                                    '&.Mui-disabled': {
                                                                        backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                                        color: 'white',           // disabled durumunda metin rengi
                                                                    },
                                                                    fontSize: "12px"
                                                                }}
                                                                variant='contained' disabled={isSuitable(year, month, day) == false} onClick={() => { setPageIndex(1); setSelectedDate(new Date(year, month, day)) }}>Randevu Talep Et</Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <MainMenu type={2} />

                <div className='container-fluid'>

                    <div className='row justify-content-center'>

                        <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px" }}>

                            <div style={{ padding: "20px" }}>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Button onClick={() => { setPageIndex(0); }} variant='contained' style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }}><i style={{ fontSize: "20px" }} class="ri-arrow-left-line"></i></Button>
                                    <div style={{ display: "flex", alignItems: "end", justifyContent: "end", flexDirection: "column" }}>
                                        <p style={{ fontSize: "30px" }}>{selectedDate.toLocaleDateString('tr-TR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        })}</p>
                                        {
                                            isDayHoliday(selectedDate) != "" &&
                                            <span style={{ fontSize: "12px", color: "red" }}>Özel Gün: {isDayHoliday(selectedDate)}</span>

                                        }
                                    </div>
                                </div>
                                <div style={{ overflow: "auto" }}>
                                    <table class="table" style={{ marginTop: "20px" }}>
                                        <thead >
                                            <tr>
                                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Saat</th>
                                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Durum</th>
                                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {timeSlots.map((slot, index) => {

                                                var randevu = getRandevu(slot)

                                                return (
                                                    <tr key={index + "012"}>
                                                        <td style={{ textAlign: "start" }} key={index}>{slot}</td>
                                                        <td style={{ textAlign: "start" }}>{randevu == undefined ? <Chip color="success" label="Uygun"></Chip> : randevu.appstate == 1 ? <Chip color="error" label="Dolu"></Chip> : <Chip color="warning" label="Talep Aşamasında"></Chip>}</td>

                                                        <td style={{ textAlign: "end" }} key={index}>
                                                            {
                                                                randevu == undefined &&


                                                                <Button
                                                                    sx={{
                                                                        color: "white", padding: "10px",
                                                                        border: "none", borderRadius: "10px",
                                                                        backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                                        '&.Mui-disabled': {
                                                                            backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                                            color: 'white',           // disabled durumunda metin rengi
                                                                        },
                                                                    }}
                                                                    disabled={new Date().setHours(0, 0, 0, 0) > selectedDate.setHours(0, 0, 0, 0) && randevu == undefined}
                                                                    variant='contained' onClick={() => {
                                                                        if (randevu == undefined) {
                                                                            setAddRandevu({
                                                                                starttime: slot.replace(/\s+/g, '').split("-")[0],
                                                                                endtime: slot.replace(/\s+/g, '').split("-")[1],
                                                                                date: formatDate(selectedDate),
                                                                                servisid: -1,
                                                                                name: "",
                                                                                phone: "",
                                                                                mail: "",
                                                                                birthdate: new Date().toLocaleDateString('tr-TR', {
                                                                                    day: '2-digit',
                                                                                    month: '2-digit',
                                                                                    year: 'numeric',
                                                                                }),
                                                                                userid: null,
                                                                                state: 2
                                                                            })
                                                                            getServices()

                                                                            setIsAddRandevuModal(true)
                                                                        }

                                                                    }}>{randevu == undefined ? "Randevu Talep Et" : "Görüntüle"}</Button>
                                                            }

                                                        </td>
                                                    </tr>

                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    isAddRandevuModal &&
                                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000002", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "fixed", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddRandevuModal(false)}></div>
                                        <div style={{ width: "350px", height: "auto", zIndex: "100000000000001", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Randevu Oluştur</h2>
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px", marginTop: "20px" }}>
                                                    <span style={{ marginRight: "5px" }}>Saat:</span>
                                                    <b>{addRandevu.starttime} - {addRandevu.endtime}</b>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                    <span style={{ marginRight: "5px" }}>Tarih:</span>
                                                    <b>{addRandevu.date}</b>
                                                </div>
                                                <div>
                                                    <select style={{ height: "56px", width: "100%", marginTop: "10px", borderRadius: "5px", padding: "10px", color: "#000", border: "1px solid #ccc" }} value={addRandevu.servisid} onChange={(e) => setAddRandevu({ ...addRandevu, servisid: e.target.value })}>
                                                        <option value={"-1"}>Hizmet Seç</option>
                                                        {
                                                            servicesList.map((service, index) => {
                                                                return (
                                                                    <option value={service.id}>{service.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <TextField id="outlined-basic" label="Ad Soyad" variant="outlined" type='text' placeholder='Müşteri Adı' style={{ marginTop: "10px", width: "100%", height: "40px" }} value={addRandevu.name} onChange={(e) => setAddRandevu({ ...addRandevu, name: e.target.value })} />
                                                <TextField id="outlined-basic" label="Telefon Numarası" variant="outlined" type="number" placeholder='Müşteri Telefon Numarası' style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.phone} onChange={(e) => setAddRandevu({ ...addRandevu, phone: e.target.value })} />
                                                <TextField id="outlined-basic" label="E-Posta" variant="outlined" type="text" placeholder='Müşteri E-posta' style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.mail} onChange={(e) => setAddRandevu({ ...addRandevu, mail: e.target.value })} />
                                                <TextField id="outlined-basic" label="Doğum Tarihi" variant="outlined" type="date" style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.birthdate} onChange={(e) => setAddRandevu({ ...addRandevu, birthdate: e.target.value })} />

                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                                                <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isAppointmentAddLoading} onClick={() => saveAppointment()}>
                                                    {
                                                        isAppointmentAddLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Businesses;
