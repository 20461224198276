
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isMobile } from 'react-device-detect';

function MainMenu({ type }) {

  const authenticated = useSelector(state => state.login)

  if (type == 1) {
    return (
      <div>
        <div className='container-fluid' >
          <div className='row justify-content-center' style={{ backgroundImage: 'url(seaside.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
            <div style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: "0.2",
              zIndex: "0"
            }}></div>
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ padding: "0px", zIndex: "10" }}>
              <div style={{ height: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: isMobile ? "center" : "space-between", flexDirection: isMobile ? "column" : "initial" }}>
                  <div style={{ margin: "10px", color: "white", fontSize: isMobile ? "28px" : "34px", display: "flex", justifyContent: isMobile ? "center" : "start", alignItems: "center" }}><Link style={{ textDecoration: "none", color: "white", fontFamily: "'Itim', cursive" }} to="/">RANDEVU DEFTERİM</Link></div>
                  <div style={{ margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ border: "1px solid white", borderRadius: "10px", height: "40px", width: "120px", fontSize: "14px", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}><i class="ri-user-line"></i><Link style={{ textDecoration: "none", color: "white" }} to={authenticated.isloggedin ? "/profil" : "/login"}>{authenticated.isloggedin ? "Profilim" : "Giriş Yap"}</Link></div>
                    {
                      !authenticated.isloggedin &&
                      <div style={{ height: "40px", width: "120px", fontSize: "14px", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}><Link style={{ textDecoration: "none", color: "white" }} to="/register">Üye Ol</Link></div>
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ padding: "0px", zIndex: "10" }}>
              <div style={{ marginTop: "100px", marginBottom: "50px" }}>
                <h1 style={{ color: "white", fontWeight: "bold" }}>Randevularını Kolayca Yönet</h1>
                <h3 style={{ color: "white", fontWeight: "lighter" }}>Randevularını Takip Et!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div>
        <div className='container-fluid' >
          <div className='row justify-content-center' style={{ backgroundColor: "#0B3D91", position: "relative", borderRadius: "0px 0px 50px 50px" }}>

            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ padding: "0px", zIndex: "10" }}>
              <div style={{ height: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: isMobile ? "center" : "space-between", flexDirection: isMobile ? "column" : "initial" }}>
                  <div style={{ margin: "10px", color: "white", fontSize: isMobile ? "28px" : "34px", display: "flex", justifyContent: isMobile ? "center" : "start", alignItems: "center" }}><Link style={{ textDecoration: "none", color: "white" }} to="/">RANDEVU DEFTERİM</Link></div>
                  <div style={{ margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                      !authenticated.isloggedin &&
                      // <div style={{ height: "40px", width: "120px", fontSize: "14px", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}><Link style={{ textDecoration: "none", color: "white" }} to="/register">Üye Ol</Link></div>
                      <div style={{ fontSize: "14px" }}><a href={"https://wa.me/905424981083"} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "transparent", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}><i style={{ fontSize: "16px", color: "white" }} class="ri-whatsapp-line"></i> Demo Talep Et!</a></div>

                    }

                    <div style={{ border: "1px solid white", borderRadius: "10px", height: "40px", width: "120px", fontSize: "14px", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}><i class="ri-user-line"></i><Link style={{ textDecoration: "none", color: "white" }} to={authenticated.isloggedin ? "/profil" : "/login"}>{authenticated.isloggedin ? "Profilim" : "Giriş Yap"}</Link></div>

                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ padding: "0px", zIndex: "10" }}>
              <div style={{ marginTop: "100px", marginBottom: "50px", textAlign: "center", padding: "10px" }}>

                <h1 style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Randevularını Kolayca Yönet</h1>
                <h3 style={{ color: "white", fontWeight: "lighter", textAlign: "center" }}>Müşteri memnuniyetini artır!</h3>
                <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "40px" }}><a href={"https://wa.me/905424981083"} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}><i style={{ fontSize: "16px", color: "white" }} class="ri-whatsapp-line"></i> Demo Talep et!</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default MainMenu;
