
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, TextField } from '@mui/material';

function Services({ selectedBusiness }) {

    const [servicesList, setServicesList] = useState([])
    const [isAddServiceModal, setIsAddServiceModal] = useState(false)
    const [serviceName, setServiceName] = useState("")
    const [servicePrice, setServicePrice] = useState("")
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [selectedService, setSelectedService] = useState({
        name: "",
        price: "",
        businessid: "",
        id: ""
    })


    const [isUpdateServiceModal, setIsUpdateServiceModal] = useState(false)



    const getServices = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/services");

            setServicesList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    useEffect(() => {
        getServices()
    }, [selectedBusiness])

    const saveService = async () => {

        setIsSaveLoading(true)
        try {

            const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/services",
                {
                    name: serviceName,
                    price: parseInt(servicePrice),

                }
            );

            getServices()

            setIsAddServiceModal(false)

            toast.success("Hizmet Eklenmiştir.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

        setTimeout(() => {
            setIsSaveLoading(false)
        }, 2000)


    }

    const updateService = async () => {

        setIsUpdateLoading(true)
        try {

            const response = await axiosInstance.put('/businesses/' + selectedBusiness.id + "/services/" + selectedService.id,
                {
                    name: selectedService.name,
                    price: parseInt(selectedService.price),

                }
            );

            getServices()

            setIsUpdateServiceModal(false)

            toast.success("Hizmet güncellenmiştir.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

        setTimeout(() => {
            setIsUpdateLoading(false)
        }, 2000)


    }

    const deleteService = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/services/" + id);

            getServices()


            toast.success("Hizmet başarılı bir şekilde silinmiştir")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    return (
        <div className="App">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "20px" }}>
                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => setIsAddServiceModal(true)}><i class="ri-add-line"></i>Hizmet Ekle</Button>
            </div>
            <div style={{ overflow: "auto", padding: "20px" }}>
                {
                    servicesList.length == 0 &&
                    <div style={{ marginBottom: "40px" }}>
                        İşletmende Hizmet Bulamadık!
                    </div>
                }
                {
                    servicesList.length > 0 &&

                    <table class="table" style={{ marginTop: "20px" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Hizmet</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Fiyat</th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                servicesList.map((service, index) => {
                                    return (
                                        <tr>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{service.name}</td>
                                            <td style={{ textAlign: "start", color: "#ccc", fontSize: "14px" }}>{service.price} TL</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ display: "flex", alignItems: "center", justifyContent: "end" }}><Button variant="contained" style={{ marginRight: "5px", border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => {
                                                setIsUpdateServiceModal(true)
                                                setSelectedService(service)
                                            }}><i class="ri-pencil-line"></i></Button>
                                                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#f06960", color: "white", padding: "10px" }}
                                                    onClick={() => {
                                                        deleteService(service.id)
                                                    }}
                                                ><i class="ri-delete-bin-line"></i></Button></td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                }
            </div>
            {
                isAddServiceModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddServiceModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Hizmet Ekle</h2>

                        <div>

                            <TextField id="outlined-basic" label="Hizmet Adı" variant="outlined" value={serviceName} onChange={(e) => setServiceName(e.target.value)} type='text' placeholder='Hizmet Adı' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Tutar" variant="outlined" type="number" value={servicePrice} onChange={(e) => setServicePrice(e.target.value)} placeholder='Hizmet Ücreti(TL)' style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isSaveLoading} onClick={() => saveService()}>
                                {
                                    isSaveLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isUpdateServiceModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsUpdateServiceModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Hizmet Güncelle</h2>

                        <div>

                            <TextField id="outlined-basic" label="Hizmet Adı" variant="outlined" value={selectedService.name} onChange={(e) => setSelectedService({
                                ...selectedService,
                                name: e.target.value
                            })} type='text' placeholder='Hizmet Adı' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Tutar" variant="outlined" type="number" value={selectedService.price} onChange={(e) => setSelectedService({
                                ...selectedService,
                                price: e.target.value
                            })} placeholder='Hizmet Ücreti(TL)' style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isUpdateLoading} onClick={() => updateService()}>
                                {
                                    isUpdateLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Services;
