import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";

function Kesfet() {

    const authenticated = useSelector(state => state.login)

    const [user, setUser] = useState({
        id: -1,
        balance: 0,
        phone: ""
    })
    const [notcount, setNotcount] = useState("")
    const [busscount, setBusscount] = useState("")

    useEffect(() => {
        getBalance()
    }, [])

    const getBalance = async () => {
        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + "/balance");

            setUser(response.data.balance)
            setBusscount(response.data.businessCount)
            setNotcount(response.data.notificationCount)

        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    return (
        <div className="App">
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Keşfet</h2>
                            <div style={{ marginTop: "20px", marginBottom: "50px" }}>
                                <p style={{ textAlign: "start", fontSize: "30px" }}>Merhaba <b>{authenticated.user.name}!</b></p>
                                <p style={{ textAlign: "start", fontSize: "16px" }}>Bu sayfada uygulamanın nasıl çalıştığını görebilirsin. Whatsapp ile mesaj göndermek istiyorsan bakiyeni görebilir ve bakiye satın almak için bizimle iletişime geçebilirsin. Daha kurumsal olmak için e-posta ve whatsapp kullan!</p>
                            </div>
                            <div className='container-fluid' style={{ marginBottom: "30px" }}>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                        <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, textAlign: "start" }}>
                                            <i class="ri-coin-fill"></i>
                                            <h3 style={{ textAlign: "start", fontSize: "18px" }}>Bakiye</h3>
                                            <p style={{ textAlign: "start" }}>{user.balance} Coin</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                        <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, textAlign: "start" }}>
                                            <i class="ri-notification-line"></i>
                                            <h3 style={{ textAlign: "start", fontSize: "18px" }}>Bildirimler</h3>
                                            <p style={{ textAlign: "start" }}>{notcount}</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                        <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, textAlign: "start" }}>
                                            <i class="ri-store-2-line"></i>
                                            <h3 style={{ textAlign: "start", fontSize: "18px" }}>İşletme Sayısı</h3>
                                            <p style={{ textAlign: "start" }}>{busscount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12'>
                                        <div className='container-fluid' style={{ marginBottom: "30px", marginTop: "50px" }}>
                                            <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "10px" }}>Üye ol sınırsız kullan!</h2>
                                            <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "20px" }}>Kurumsal firmalar Whatsapp kullanıyor. Sen de üye ol müşterilerine whatsapp ile mesaj yolla!</p>
                                            <div className='row'>
                                                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                                                        <div style={{ fontSize: "16px", color: "white" }}>-</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Demo Paket</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sınırsız E-Posta</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sınırsız Whatsapp Mesajı</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Otomatik Mesajlar</div>

                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", textDecoration: "line-through", color: "black", height: "20px" }}></div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold",color:"orange" }}>ÜCRETSİZ!!</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://wa.me/905424981083"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Demo Talep Et</a></div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                                                        <div style={{ fontSize: "16px", color: "white" }}>-</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Aylık Paket</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız E-Posta</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Whatsapp Mesajı</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Otomatik Mesajlar</div>

                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", textDecoration: "line-through", color: "black", height: "20px" }}></div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold" }}>1990 TL</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a target="_blank" href={"https://wa.me/905424981083"} style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10, outline: "none", border: "none", fontWeight: "bold" }}> Satın Al</a></div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                                                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                                                        <div style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>11.688 TL Kârdasın</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Yıllık Paket</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız İşletme</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Personel</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Randevu</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız E-Posta</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Sınırsız Whatsapp Mesajı</div>
                                                        <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Otomatik Mesajlar</div>

                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", textDecoration: "line-through", color: "black", height: "20px" }}>23.988 TL</div>
                                                        <div style={{ fontSize: "24px", fontWeight: "bold", color: "orange" }}>12.000 TL</div>

                                                        <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a target="_blank" href={"https://wa.me/905424981083"} style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10, outline: "none", border: "none", fontWeight: "bold" }}> Satın Al</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container-fluid' style={{ marginTop: "100px" }}>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='container-fluid'>
                                            <div className='row justify-content-center'>
                                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>

                                                    <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <div>
                                                            <h3 style={{ fontSize: "34px" }}>Nasıl Çalışır?</h3>
                                                            <p style={{ fontSize: "18px", color: "#4f4f4f" }}>
                                                                Uygulamanın nasıl çalıştığını yandaki videoyu izleyerek öğrenebilirsin.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style={{ marginTop: "100px" }}>
                                                    <div style={{ height: "600px", textAlign: "center" }}>
                                                        <iframe
                                                            style={{ borderRadius: "30px", border: "1px solid #aaa" }}
                                                            src="https://drive.google.com/file/d/1xaQIVLNigSx4RRUD9Yv3sg3dcZuyU1I8/preview"
                                                            width="100%"
                                                            height="480"
                                                            allow="autoplay"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kesfet;
