
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

function Customers({ selectedBusiness }) {

    const [customerList, setCustomerList] = useState([])
    const [isAddCustomerModal, setIsAddCustomerModal] = useState(false)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mail, setMail] = useState("")
    const [birthdate, setBirthdate] = useState(new Date())
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState({
        id: -1,
        name: "",
        phone: "",
        mail: "",
        birthdate: ""
    })

    const [isSendMailModal, setIsSendMailModal] = useState(false)
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({
        id: -1,
        businessid: -1,
        message: "",
        title: ""
    })
    const [isUpdateCustomerModal, setIsUpdateCustomerModal] = useState(false)
    const [isSendMailLoading, setIsSendMailLoading] = useState(false)


    const [isSendWpModal, setIsSendWpModal] = useState(false)


    const getMessages = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/messages");

            setMessages(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    const sendWp = async () => {

        if (selectedMessage.id != -1) {

            setIsSendMailLoading(true)
            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/whatsapp",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: selectedCustomer.phone
                    }
                );

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                setIsSendWpModal(false)

                toast.success("Whatsapp mesajı yollanmıştır.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)
        }
        else {
            toast.error("Lütfen Mesaj Şablonu seçiniz.")
        }

    }

    const sendMail = async () => {

        if (selectedMessage.id != -1) {

            setIsSendMailLoading(true)
            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/operations",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: selectedCustomer.mail
                    }
                );

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                setIsSendMailModal(false)

                toast.success("E-Posta yollanmıştır.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)
        }
        else {
            toast.error("Lütfen Mesaj Şablonu seçiniz.")
        }

    }



    const getCustomers = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/customers");

            setCustomerList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

    }

    useEffect(() => {
        getCustomers()
        getMessages()
    }, [selectedBusiness])

    const saveService = async () => {

        setIsSaveLoading(true)
        try {

            const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/customers",
                {
                    name,
                    phone,
                    mail,
                    birthdate

                }
            );

            getCustomers()

            setIsAddCustomerModal(false)

            toast.success("Müşteri başarıyla Eklenmiştir.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

        setTimeout(() => {
            setIsSaveLoading(false)
        }, 2000)


    }

    const updateCustomer = async () => {

        setIsUpdateLoading(true)
        try {

            const response = await axiosInstance.put('/businesses/' + selectedBusiness.id + "/customers/" + selectedCustomer.id,
                {
                    name: selectedCustomer.name,
                    phone: selectedCustomer.phone,
                    mail: selectedCustomer.mail,
                    birthdate: selectedCustomer.birthdate,
                }
            );

            getCustomers()

            setIsUpdateCustomerModal(false)

            toast.success("Müşteri güncellenmiştir.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
            }

        }

        setTimeout(() => {
            setIsUpdateLoading(false)
        }, 2000)


    }



    return (
        <div className="App">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                <p style={{ margin: "0px", fontSize: "14px", color: "#969696" }}>{customerList.length} adet müşteri listeleniyor</p>
                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => setIsAddCustomerModal(true)}><i class="ri-add-line"></i>Müşteri Ekle</Button>
            </div>
            <div style={{ overflow: "auto", padding: "20px" }}>
                {
                    customerList.length == 0 &&
                    <div style={{ marginBottom: "40px" }}>
                        İşletmende Müşteri Bulamadık
                    </div>
                }
                {
                    customerList.length > 0 &&

                    <table class="table" style={{ marginTop: "20px" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>İsim</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Telefon</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>E-Posta</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Doğum Tarihi</th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customerList.map((customer, index) => {
                                    return (
                                        <tr>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{customer.name}</td>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{customer.phone}</td>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{customer.mail}</td>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{customer.birthdate.split("T")[0]}</td>
                                            <td></td>
                                            <td style={{ display: "flex", alignItems: "center", justifyContent: "end" }}><Button variant="contained" style={{ marginRight: "5px", border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px", fontSize: "16px" }} onClick={() => {
                                                setIsUpdateCustomerModal(true)
                                                setSelectedCustomer(customer)
                                            }}><i class="ri-pencil-line"></i></Button>
                                                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#16c7cd", color: "white", padding: "10px", fontSize: "16px", width: "auto" }} onClick={() => { setIsSendMailModal(true); setSelectedCustomer(customer) }}>
                                                    <i class="ri-send-plane-fill"></i>
                                                </Button>

                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                }
            </div>
            {
                isAddCustomerModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddCustomerModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Müşteri Ekle</h2>

                        <div>
                            <TextField id="outlined-basic" label="Müşteri Adı" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Hizmet Adı' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Telefon" variant="outlined" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Telefon' style={{ marginTop: "30px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="E-Posta" variant="outlined" type="text" value={mail} onChange={(e) => setMail(e.target.value)} placeholder='Telefon' style={{ marginTop: "30px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Doğum Günü" variant="outlined" type="date" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isSaveLoading} onClick={() => saveService()}>
                                {
                                    isSaveLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isUpdateCustomerModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsUpdateCustomerModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Müşteri Güncelle</h2>

                        <div>
                            <TextField id="outlined-basic" label="Müşteri Adı" variant="outlined" value={selectedCustomer.name} onChange={(e) => setSelectedCustomer({
                                ...selectedCustomer,
                                name: e.target.value
                            })} type='text' placeholder='Hizmet Adı' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Telefon" variant="outlined" type="text" value={selectedCustomer.phone} onChange={(e) => setSelectedCustomer({
                                ...selectedCustomer,
                                phone: e.target.value
                            })} placeholder='Telefon' style={{ marginTop: "30px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="E-Posta" variant="outlined" type="text" value={selectedCustomer.mail} onChange={(e) => setSelectedCustomer({
                                ...selectedCustomer,
                                mail: e.target.value
                            })} placeholder='Telefon' style={{ marginTop: "30px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Doğum Günü" variant="outlined" type="date" value={selectedCustomer.birthdate.split("T")[0]} onChange={(e) => setSelectedCustomer({
                                ...selectedCustomer,
                                birthdate: e.target.value
                            })} placeholder='Doğum Günü' style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isUpdateLoading} onClick={() => updateCustomer()}>
                                {
                                    isUpdateLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isSendMailModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsSendMailModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>E-Posta Yolla</h2>

                        <div>

                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>İsim:  <b>{selectedCustomer.name}</b></h4>
                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>E-Posta: <b>{selectedCustomer.mail}</b></h4>
                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>Telefon: <b>{selectedCustomer.phone}</b></h4>
                            <FormControl fullWidth style={{ marginTop: "30px", width: "100%", height: "40px" }} >
                                <InputLabel id="demo-simple-select-label">Mesaj Şablonu</InputLabel>
                                <Select
                                    value={selectedMessage.id} onChange={(e) => {
                                        var i;
                                        for (i = 0; i < messages.length; i++) {
                                            if (e.target.value == messages[i].id)
                                                setSelectedMessage(messages[i])
                                        }
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Mesaj Şablonu"
                                >
                                    {
                                        messages.map((message, index) => {
                                            return (
                                                <MenuItem value={message.id}><b>{message.title}</b>:{message.message}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        {
                            isSendMailLoading &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                                <div class="spinner-border text-dark" role="status"></div>

                            </div>
                        }
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                            <Button variant="contained" style={{ width: "100%", padding: "10px", borderRadius: "10px", backgroundColor: isSendMailLoading ? "#ccc" : "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendMail()}>

                                E-Posta Yolla

                            </Button>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                            <Button variant="contained" style={{ width: "100%", padding: "10px", borderRadius: "10px", backgroundColor: isSendMailLoading ? "#ccc" : "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendWp()}>
                                Whatsapp Mesajı Gönder
                                (1 <i style={{ color: "#F0BB40" }} class="ri-coin-fill"></i>)
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isSendWpModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsSendWpModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                        <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Whatsapp Mesajı Yolla</h2>

                        <div>

                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>İsim:  <b>{selectedCustomer.name}</b></h4>
                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>E-Posta: <b>{selectedCustomer.mail}</b></h4>
                            <h4 style={{ fontSize: "14px", textAlign: "start" }}>Telefon: <b>{selectedCustomer.phone}</b></h4>
                            <FormControl fullWidth style={{ marginTop: "30px", width: "100%", height: "40px" }} >
                                <InputLabel id="demo-simple-select-label">Mesaj Şablonu</InputLabel>
                                <Select
                                    value={selectedMessage.id} onChange={(e) => {
                                        var i;
                                        for (i = 0; i < messages.length; i++) {
                                            if (e.target.value == messages[i].id)
                                                setSelectedMessage(messages[i])
                                        }
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Mesaj Şablonu"
                                >
                                    {
                                        messages.map((message, index) => {
                                            return (
                                                <MenuItem value={message.id}><b>{message.title}</b>:{message.message}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendWp()}>
                                {
                                    isSendMailLoading ? <div class="spinner-border text-light" role="status"></div> : "Whatsapp Mesajı Gönder"

                                }
                                (1 <i style={{ color: "#F0BB40" }} class="ri-coin-fill"></i>)
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Customers;
