
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { useState } from 'react';
import axiosInstance from '../../Axios';
import { login } from '../../Redux/Actions/login';
import md5 from "md5";
import { Helmet } from 'react-helmet-async';

function Register() {

    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [phoneMessage, setPhoneMessage] = useState("")
    const [nameMessage, setNameMessage] = useState("")
    const [mailMessage, setMailMessage] = useState("")
    const [passwordMessage, setPasswordMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [birthdate, setBirthdate] = useState(new Date())
    const [loginMessage, setLoginMessage] = useState("")
    const [checked, setChecked] = useState(true)

    const registerHandle = async () => {

        if (password.length > 4) {

            setLoading(true);

            try {
                const response = await axiosInstance.post('/users',
                    {
                        name,
                        mail,
                        password: md5(password),
                        birthdate,
                        phone
                    });

                setTimeout(() => {
                    dispatch(login(response.data.user, response.data.token))
                    localStorage.setItem("user", JSON.stringify(response.data.user))
                    localStorage.setItem("token", response.data.token)
                    setLoading(false)
                    window.location.href = "/kesfet"
                }, 2000)


            } catch (error) {

                setTimeout(() => {
                    setLoginMessage(error.response != undefined ? error.response.data.error : "Bağlantı problemi yaşıyoruz. Lütfen daha sonra tekrar deneyiniz.")
                    setLoading(false);
                }, 2000)

            }

        }
        else {
            setPasswordMessage("Şifre alanı 4 karakterden çok olmalıdır.")
        }

    }
    

    return (
        <div className="App">
            <Helmet>
                <title>Üye Ol</title>
                <meta name='description' content='Randevu defterime hemen üye ol. Randevu takip sistemini kullanmaya hemen başla. Ücretsiz olan bu uygulamada sınırsız personel ekleyebilirsiniz. Müşterilerinize e-postalar yollayabilirsiniz.' />
            </Helmet>
            <MainMenu type={2} />
            <div>
                <div className='container-fluid' >
                    <div className='row justify-content-center'>
                        <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-3' style={{ padding: "0px", zIndex: "10" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                <div style={{ border: "1px solid #D9D9D9", borderRadius: "10px", height: "auto", width: "100%", padding: "20px", marginBottom: "30px" }}>
                                    {
                                        loginMessage.length > 0 &&
                                        <div class="alert alert-danger" role="alert">
                                            {loginMessage}
                                        </div>
                                    }
                                    <h1 style={{ marginTop: "50px", marginBottom: "20px" }}>Hemen Üye Ol</h1>
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{nameMessage}</p>

                                    <input disabled={loading} value={name} onChange={(e) => {

                                        if (e.target.value.length < 2 || e.target.value.length > 200) {
                                            setName(e.target.value)
                                            setNameMessage("İsim en az 2 en çok 200 karakter olmalıdır!")

                                        }
                                        else {
                                            setName(e.target.value)
                                            setNameMessage("")
                                        }
                                    }} type="text" style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px", MozAppearance: "textfield" }} placeholder='İsim Soyisim' />
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{phoneMessage}</p>
                                    <input disabled={loading} value={phone} onChange={(e) => {
                                        const phoneRegex = /^0\d{10}$/;

                                        if (phoneRegex.test(e.target.value)) {
                                            setPhone(e.target.value)
                                            setPhoneMessage("")
                                        }
                                        else {
                                            setPhone(e.target.value)
                                            setPhoneMessage("Telefon numarasını hatalı!")
                                        }
                                    }} type="number" style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px", MozAppearance: "textfield" }} placeholder='Telefon Numarası' />
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{mailMessage}</p>
                                    <input disabled={loading} value={mail} onChange={(e) => {
                                        const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                        if (mailRegex.test(e.target.value)) {
                                            setMail(e.target.value)
                                            setMailMessage("")
                                        }
                                        else {
                                            setMail(e.target.value)
                                            setMailMessage("E-Posta adresi hatalı!")
                                        }
                                    }} type="text" style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px", MozAppearance: "textfield" }} placeholder='E-Posta Adresi' />
                                    <p style={{ fontSize: "14px", margin: "0px", marginTop: "10px", textAlign: "start", color: "#969696" }}>Doğum Tarihi</p>
                                    <input disabled={loading} value={birthdate} onChange={(e) => {
                                        setBirthdate(e.target.value)
                                    }} type='date' style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px" }} placeholder='Doğum Tarihi' />
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{passwordMessage}</p>
                                    <input disabled={loading} value={password} onChange={(e) => {
                                        if (e.target.value.length < 5 || e.target.value.length > 35) {
                                            setPassword(e.target.value)
                                            setPasswordMessage("Şifre 4 karakterden çok olmalı")
                                        }
                                        else {
                                            setPassword(e.target.value)
                                            setPasswordMessage("")
                                        }
                                    }} type='password' style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px" }} placeholder='Şifre' />

                                    <div style={{ marginTop: "10px" }}>
                                        <input type='checkbox' onChange={(e) => setChecked(!e.target.checked)} /> Verilerimin bana ve müşterilerime ulaşılması için kullanılmasını onaylıyorum.
                                    </div>
                                    <button disabled={loading || checked} style={{ width: "100%", height: "51px", marginTop: "20px", borderRadius: "10px", outline: "none", backgroundColor: !checked?"#16CDB7":"#ccc", border: "none", color: "white" }} onClick={() => registerHandle()}>{loading ? <div class="spinner-border text-light" role="status"></div> : "Giriş Yap"}</button>
                                    <Link style={{ textDecoration: "none", display: "block", marginTop: "20px", fontSize: "14px", color: "black" }} to="/sifremiunuttum">Şifremi Unuttum</Link>
                                    <Link style={{ textDecoration: "none", display: "block", marginTop: "20px", fontSize: "14px" }} to="/login">Giriş Yap</Link>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
